.accordian {
    max-width: 600px;
    display: block;
    margin: 100px auto;
}



.accordian .card .card-header h3 {
    cursor: pointer;
    color: #3343a2;
    position: relative;
    background-color: transparent;
    margin: 0;
    padding: 15px 20px;

}
.ukt{
   

   
    color: #3343a2;
margin-left: 10px;
  font-weight: bold;
    line-height: 25px;
    font-size: 18px;  
}
.accordian .card .card-header {
    position: relative;
}

.accordian .card .card-header span {
  
    height: 25px;
    width: 25px;
    color: #3343a2;
    background-color: transparent;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    font-size: 13px;
}

.accnone {
    display: none;
}

.accordian .card .card-body {
    padding: 20px;
}

.accordian .card .card-body {
    display: none;
}

/*open one card by default*/


.accordian .card .card-body p {
    font-size: 15px;
    line-height: 24px;
    color: #444444;
    margin: 0px;
}


@media only screen and (max-width: 600px) {
    .accnone {
       
        display: block;
    }
}



/* Accordion container */
.accordion-faq {
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;
  }
  
  /* Accordion header */
  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Align items horizontally */
    cursor: pointer;
    padding: 10px;
    background-color: #f5f5f5;
    user-select: none;
  }
  .accordion-toggle {
    font-size: 35px; /* Adjust the value as needed */
    color: #3D4DAE;
    margin-right: 20px;
  }
  /* Plus/Minus icon */
  .accordion-icon {
    font-size: 1.2rem;
    margin-left: 10px;
  }
  
  /* Question text */
  .accordion-question {
    font-weight: bold;
  }
  
  /* Answer text */
  .accordion-answer {
    padding: 10px;
    background-color: #ffffff;
  }
  
  /* Your existing CSS styles */
  
  /* Additional style for the question icon */
  .question-icon {
    width: 50px;
    /* Adjust as needed */
    height: 50px;
    /* Adjust as needed */
    margin-right: 20px;
  }
  
  .accordion {
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }
  
  .accordion-item {
    border-top: 1px solid #ddd;
  }
  
  .accordion-header {
    background-color: #f5f5f5;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-header.active {
    background-color: #e0e0e0;
  }
  
  .accordion-content {
    padding: 10px;
    display: block;
  }