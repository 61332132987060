.slider1 {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 50px;
  }
  .mobslider{

  width: 600px;
}
.Sli{
  width: 1000px;
}
.cols1{
   
}
.cols2{
 padding-left: 65% !important;
}
@media only screen and (max-width: 600px) {
  .mobslider {
    width: 100%;  }
    .Sli{
      width: 140%;
    }
    .cols2{
        padding-left: 30% !important;
       }
  }
  .thumb1 {
    width: 20px;
    height: 20px;
    background: radial-gradient(circle at center, #20b2aa, #00008b);
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0px 0px 0px 3px #00396D, 0px 0px 0px 5px #fff, 0px 0px 10px 7px rgba(30,144,255,0.5);
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .track1 {
    height: 5px;
    background: #ddd;
    position: relative;
    width: 100%;
  }
  
  .range1 {
    position: absolute;
    height: 5px;
    background: #00396D;
    z-index: 1;
  }
  
  
  .value1 {
    color: grey;
    font-weight: bolder;
    position: absolute;
    top: -50px; /* Increase this value to add more space */
  }
  
  