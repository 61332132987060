
.Navbara{
    width: 100%;
    background-color: white;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left:15px ;
    padding-right: 15px;
}
.accordion-header {
    padding: 2px !important;
}

.navlogoa{
    height: 40px;
    width: 130PX;
 
}
.leftside{
    width:60% ;
   
}
.rightside{
    width:40% ;
    text-align: right;
    justify-content: right;
    display: flex;
   
}
.toggle{
    width: 34px;
    height: 34px;

    display: flex;
    justify-content: center;
    align-items: center;
   
}
.listdiv{
    margin-left: 10px;
    border-left: 1px solid lightgray;
    padding-left: 20px;
}