.hero-bg-about {
  background: #0659a4;
 

}
.container5{
  width: 90%;
  display: flex;
}
.afterworking{
  padding: 14px;
}
.mobpad1{
  margin: 0;
}
.section_1 {
  /* border-radius: 0px 0px 450px 450px;
    background: #BADA55; */
  background-image: url("../Assets/Frame\ 269.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: auto;
}
.imgmob{
  display: none;
}
.our_story_card {
  background: radial-gradient(
      128.04% 307.49% at 121.43% -46.19%,
      #42b1e1 0.45%,
      #0659a4 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  margin: auto;
}

.our_team {
  background: #f2ffff;
}
@media (max-width: 480px) {
 .fontabout{
  font-size: 40px !important;
 }
 .text-primary{
  margin-top: 20px;
  font-size: 15px;
  font-weight: bold;
 }
 .new{
  width: 100%;
 }
 .textcent{
  text-align: center;
  margin: 0px;
  padding: 0px !important;
  
 }
 .imgmob{
  display: block;
}
.launch{
  margin-left:50px;
}
.titleA{
  display: flex;
  flex-direction: column;
  align-items: start;

}
.titleA>span{
  margin-left: 10px;
}
.titleA>h1{
  margin-left: 10px;
}
.mobpad1{
  margin: 40;
}
.our_story_card {
  background: radial-gradient(
      128.04% 307.49% at 121.43% -46.19%,
      #42b1e1 0.45%,
      #0659a4 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  height: 600px;
  width: 100%;

  text-align: center;
  
}
}

.card-container{
  @media screen and (max-width: 800px) {
   width: 100%;
}
}
