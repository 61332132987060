@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');

.ourventure_baner {
  height: 27vh;
  width: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #0659A4;
  background-image: url("../Assets/Banner\ Background.png");
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  box-sizing: border-box;
}

.mood {
  display: block;
}

.moo1 {
  display: none !important;
}

.Venture_heading {
  color: white;
  margin-left: 10vw;
  font-weight: bolder;
  font-size: 40px;
}

.Main_container_venture {
  padding: 0% 10%;
  /* background-color: rebeccapurple; */
  /* background-color: #d9d9d9; */
}

.line_color {
  background-color: #0659A4;
  height: 5px;
  width: 100px;
  margin-bottom: 10px;
}

.Secound_venture {
  height: 55vh;
  width: 100%;
  background-color: #d9d9d97a;
  ;
  padding: 0% 10%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.five_venture {
  height: 60vh;
  width: 100%;
  /* background-color: aquamarine; */
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 0% 10%;
  background-color: white;
}

.six_venture {
  height: 65vh;
  width: 100%;
  padding: 0% 10%;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
 
}

.four_venture {
  height: 60vh;
  width: 100%;
  padding: 0% 10%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.third_venture {
  height: 70vh;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 0% 10%;
}

.text_card_venture {
  font-size: 12px;

  display: flex;
  text-align: center;
  padding: 2%;
}

.head3 {
  color: #0659A4;
  font-weight: bolder;
  font-size: 35px;
}

.cards_venture {
  height: 50vh;
  width: 100%;
  padding: 2px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.subcards_venture {
  height: 100%;
  width: 22%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 10px;
  

}

.main_crad_parent {
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

.heading_sub_venture {
  color: #0659a4;
  font-family: 'Segoe UI', sans-serif;

}

.img_text_venture {
  margin-top: 10px;
  width: 60%;
  height: 100%;
  color: #686868;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media screen and (max-width: 425px) {
    margin-bottom: 21%;
  }
}

.img_venture {
  /* background-image: url('../Assets/land-rev-1-img-2\ 1.png');
    background-repeat: no-repeat;
    background-size:cover; */
  width: 55%;
  height: 111%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20%;
}

.txt_venture_sub {
  color: #686868;
  font-size: 14px;

}

.sizing {
  height: 80%;
  width: 58%;
}

.questionsizsing {
  height: 40%;
  width: 20%;
  margin-bottom: 40%;
  margin-right: -10%;
}

.sizing2 {
  height: 50%;
  width: 85%;
}

.sizing_mar_2 {
  height: 60%;
  width: 95%;
  margin-right: 100%;
}

.fixe_bg {
  background-position: fixed !important;
}

.blue_color {
  color: #0659A4;
}

.small_size {
  font-size: 12px;
}

.boxes_question {
  margin-bottom: 5px;
}
@media screen and (max-width: 880px) {
  
  .six_venture {
    height: 100%;
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
 
    margin-bottom: 40px;
    margin-top: 50px;
      
  }
}

@media screen and (max-width: 480px) {

  .sizing_mar_2{
    display: none !important;
  }
  .Secound_venture {
    height: 90vh;
    width: 100%;
    /* background-color: green; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .five_venture {
    height: 60vh;
    width: 100%;
    /* background-color: green; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .vent {
    margin-top: -50vh;
    margin-bottom: -15vh;
  }

  .moo1 {
    display: block !important;
  }

  .six_venture {
    height: 100%;
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
 
    margin-bottom: 40px;
    margin-top: 2px;
      
  }

  .sizing2{
   display: none;
    background-color: red;
  }
  .four_venture {
    height: 90vh;
    width: 100%;
    /* background-color: green; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .third_venture {
    height: 90vh;
    width: 100%;
    /* background-color: green; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 10vh;
  }

  .img_venture {
    /* background-image: url('../Assets/land-rev-1-img-2\ 1.png');
    background-repeat: no-repeat;
    background-size:cover; */
    width: 100%;
    height: 50%;
    margin-left: 0%;
    display: none;
  }

  .img_text_venture {
    width: 100%;
    height: 50%;
    margin-top: 30px;
  }

  .subcards_venture {
    height: 40%;
    width: 100%;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 5px;
    padding: 5px;
    padding-top: 10px;
  }

  .main_crad_parent {
    width: 90%;
    height: 70vh;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .mood {
    display: none;
  }

  .cards_venture {
    height: 100vh;
    width: 100%;
    padding: 2px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

  }

  .sizing_mar_2 {
    height: 60%;
    width: 95%;
    margin-right: 0px;

  }

  .card_img_sizing {
    height: 30px;
  }



}