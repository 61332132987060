.banner_Solution1 {

  width: 100%;
  background-image: url('../Assets/newcrp.png');
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;


}


.sixcard2{
  margin-top: 50px;
}
.cardnew2 {
  height: 50%;
  width: 560px;
  border: 1px solid #c7c7c7;
  margin: 10px;
  padding: 25px;
  
}
.sixcard1{

  margin-top:400px ;
}
.cardnew2:hover {
  height: 290px;
  width: 560px;
  border: none;
  margin: 10px;
  padding: 25px;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
}

.cardnew2 h4 {
  color: #3D4DAE;
  font-size: 24px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  margin-bottom: 15px;
}

.cardnew2 p {
  font-size: 15px;
  color: #686868;
  font-weight: 400;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.cardsec {
  border: 1px solid #c7c7c7;
  height: 390px;
  width: 280px;
  margin: 20px;

}

.cardsec:hover {

  height: 390px;
  width: 280px;
  margin: 20px;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
}

.banner_Solution2 {

  width: 100%;
  background-image: url('../Assets/newchart.png');
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 425px) {
    padding-bottom: 400px;
    height: auto;
}

}

.banner_Solution3 {

  width: 100%;
  background-image: url('../Assets/newsol2.png');
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 425px) {
    padding-bottom: 500px;
    height: auto;
}

}

.banner_Solution4 {

  width: 100%;

  background-image: url('../Assets/newerp.png');
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 425px) {
            padding-bottom: 500px;
            height: auto;
  }
      

}

.banner_Solution5 {

  width: 100%;
  background-image: url('../Assets/newagri.png');
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 425px) {
    padding-bottom: 500px;
    height: auto;
}

}

.banner_Solution6 {

  width: 100%;
  background-image: url('../Assets/newmed.png');
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;


}

.Solutionbanner1_section {
  width: 50%;
  height: 50%;
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #ffffff;
  margin: 2% 2%;
  
}

.secoundlast_solution {
  background-color: #09197a;
  height: 150vh;
  width: 80%;
}

.section3_solution {
  height: 75vh;
  width: 100%;
  background-image: url("../Assets/Frame\ 287.png");
  margin-top: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.section3_solution2 {
  height: 75vh;
  width: 100%;
  background-image: url("../Assets/Frame\ 287.png");
  margin-top: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.section3_solution3 {
  height: 75vh;
  width: 100%;
  background-image: url("../Assets/Frame\ 287.png");
  margin-top: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.card2_sol1_sub {
  /* background-color: #3D4DAE; */
  height: 80%;
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.Solutionbanner2_section {
  width: 40%;
  height: 0%;
  /* background-color: red; */
}

.imgsizing_sol1 {
  height: 100%;
  width: auto;
}

.section1_solution {
  height: 50vh;
  width: 80%;
  /* background-color: red; */
  display: flex;
}

.section2_solution {
  height: 100%;
  width: 90%;
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card_container_solu1 {
  /* background-color: gray; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.card_container_solu2 {
  /* background-color: gray; */
  height: 60%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.sub_card_sol1 {
  height: 27%;
  width: 30%;
  /* background-color: antiquewhite; */
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
}

.sub_cards_sol1 {
  height: 45%;
  width: 26%;
  padding: 5% 5%;
  /* background-color: antiquewhite; */
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  border: 1px solid rgb(182, 179, 179);
  border-radius: 5px;
  background-color: #696868 !important;
}


.sub_card_sol1:hover {

  /* background-color: antiquewhite; */
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  background-color: #ffffff;
  border: none;
}

.sub_cards_sol1:hover {


  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);

}

.bottom_card_dol {
  height: auto;
  width: auto;
  /* background-color: #09197A; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
  flex-wrap: wrap;


}

.sub_card_sol2 {
  height: 45%;
  width: 30%;
  /* background-color: antiquewhite; */
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  background-color: #ffffff;
}

.section1_content {
  width: 70%;
  height: 100%;
  align-content: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section2_img {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_body_soltions {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: black; */
}

.imgsizing_sol2 {
  width: 90%;
}

.btn_sol1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_sub_col1 {
  width: 200px;
  background-color: #3d4dae;
  box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #ffffff;
  height: 40px;
  border: none;
  margin-left: -20px;
  outline:
   none;
}

@media screen and (max-width: 800px){
  
  .cardnew{
    height: 50%;
    width: 100%;
    border: none;
    margin: 10px;
    padding: 25px;
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
  }
.cardnew:hover {
  height: 50%;
  width: 100%;
  border: none;
  margin: 10px;
  padding: 25px;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
}

.cardnew h4 {
  color: #3D4DAE;
  font-size: 24px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  margin-bottom: 15px;
}

.cardnew p {
  font-size: 15px;
  color: #686868;
  font-weight: 400;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.sub_card_sol1 {
  height: 80%;
  width: 33%;

  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  background-color: #ffffff;
}
.container2{
  display: flex;
  flex-direction: column;


}

}

@media screen and (max-width: 480px) {
  .banner_Solution1 {
    height: 70vh;
    width: auto;
    background: linear-gradient(90deg, #09197A 34.42%, #3D4DAE 100%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .sixcard4{
    margin-top:650px !important;
  }
  .sixcard1{

    margin-top:137% ;
  }
  .sixcard2{
    margin-top: 50px;
  }
  .cardnew {
    height: 100%;
    width: 100%;
    border: 1px solid #c7c7c7;
    margin: 10px;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 20px;

  }

  .cardnew:hover {
    height: 100%;
    width: 100%;
    border: none;
    margin: 10px;
    padding: 15px;
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
  }

  .section2_solution {
    height: 130%;
    width: 80%;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .banner_Solution4 {
    height: 70vh;
    width: auto;
    background: linear-gradient(90deg, #09197A 34.42%, #3D4DAE 100%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .banner_Solution2 {
    height: 70vh;
    width: auto;
    background: linear-gradient(90deg, #09197A 34.42%, #3D4DAE 100%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .banner_Solution3 {
    height: 70vh;
    width: auto;
    background: linear-gradient(90deg, #09197A 34.42%, #3D4DAE 100%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .ppp {
    margin-top: 50%;
  }

  .banner_Solution5 {
    height: 70vh;
    width: auto;
    background: linear-gradient(90deg, #09197A 34.42%, #3D4DAE 100%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .banner_Solution6 {
    height: 70vh;
    width: auto;
    background: linear-gradient(90deg, #09197A 34.42%, #3D4DAE 100%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .Solutionbanner1_section {
    width: 70%;
    height: 50%;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: #ffffff;
  }

  .Solutionbanner2_section {
    width: 80%;
    height: 50%;
    /* background-color: red; */
  }

  .imgsizing_sol1 {
    height: 100%;
    width: auto;
  }
  .sixcard3{
    margin-top:650px ;
   
  }
  .section1_solution {
    height: 100vh;
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .section1_content {
    width: 100%;
    height: 70%;

    align-content: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .section3_solution {
    display: none;
  }

  .section2_img {
    width: 100%;
    height: 70%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section2_solution {
    height: 2050px;
    width: 80%;
    /* background-color: aqua; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin-left: 25px;
    margin-bottom: -150px;
  }

  .card_container_solu1 {
    /* background-color: gray; */
    height: 90%;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .sub_card_sol1 {
    height: 30%;
    width: 90%;

    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    background-color: #ffffff;
  }

  .sub_cards_sol1 {
    height: 10%;
    width: 90%;
    /* background-color: antiquewhite; */
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    background-color: #ffffff;
  }
}

.text-askari-primary {
  color: white !important;
  margin-top: 5%;
}
.text-askari-primary1 {
  color: #00396D ;
  margin-top: 5%;
}
.client_connect_app_card {
  margin: 5px;
  height: 280px !important;
  border: none !important;
}

.client_connect_app_card:hover {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
}

.empowering_business {
  background-image: url("../Assets/newbuttom.png");
  padding: 30px;
  height: 50vh;
  background-repeat: no-repeat;
  margin-top: -50px;


}