.mobslider{

  width: 600px;
}
.Sli{
  width: 1000px;
}
@media only screen and (max-width: 600px) {
  .mobslider {
    width: 100%;  }
    .Sli{
      width: 140%;
    }
  }