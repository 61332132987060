.container-fluid2 {

    width: 100%;
    display: flex;
  
    justify-content: space-between;

    height: 100%;
    margin: 20px;

}
