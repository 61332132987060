* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.for-baground-images {
    background-image: url(../Assets/shopfygrade.png);
    background-size: cover;
    width: 100%;
    height: 70vh;
    
}
.carddiv{
    display: flex;
    flex-direction: column;
}
.cardnew{
    width: 25%;
    margin: 5PX;
    text-align: center;
    background: #FEFEFE;
    box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25); 
    padding: 1rem;
  
 
}
.card-main-div-main-card {
    width: 22% !important;
    height: auto ;
  
  }

.buto{
width: 360px;
height: 50px;
background-color: white;
border: none;
border-radius: 5px;
margin-top: 30px;
margin-bottom: 30px;

}
.contt{
background-color: #07135F;
display: flex;
justify-content: center;
align-items: center;
color: white;
padding-top: 40px;
padding-bottom: 40px;
}
.newcardd{
    height: 40%;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 20px!important;
    padding: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
   
}
.for-baground-images1 {
    background-image: url(../Assets/shopfygrade.png);
    background-size: cover;
    width: 100%;
    height: 70vh;
    
}
.for-baground-images2 {
    background-image: url(../Assets/shopfygrade.png);
    background-size: cover;
    width: 100%;
    height: 70vh;
    
}
.prob{
    margin-top: 60px;
}
.blueset{
    height: 470px !important;
    padding-left: 30px;
}

.set1{
    margin-left: 130px !important;
   
}
.set3{
    margin-left: 170px !important;
   
}
.this-magento{
    background-image: url(../Assets/MagentoBanner.png);
    background-size: cover;
    width: 100%;
    height: 100%;
}
.Wocommer-banner{
    background-image: url(../Assets/WoocommerBanner.png);
    background-size: cover;
    width: 100%;
    height: 100%;
}
.Shopfiy-app-main{
    background-image: url(../Assets/ShopfyAppBanner.png);
    background-size: cover;
    width: 100%;
    height: 100%;
}
.Enter-prices-images{
    background-image: url(../Assets/EnterpricesBanner.png);
    background-size: cover;
    width: 100%;
    height: 100%;
}
.dynamic-365{
    background-image: url(../Assets/EcoomerceAppBanner.png);
    background-size: cover;
    width: 100%;
    height: 100%;
}
.odoo-img{
    background-image: url(../Assets/odooBanner.png);
    background-size: cover;
    width: 100%;
    height: 100%;
}
.main-hero-h1 {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 3rem;
    color: #FFFFFF;
   
   
}

.main-hero-para-main-div {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 350;
    font-size: 16px;
    color: #FFFFFF;
}

.main-home-shopyt-btn {
    width: fit-content;
    height: fit-content;
    background: #FFFFFF;
    padding: 0.9rem;
    border-radius: 3px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.image-main-section img {
    height: 700px;
    width: 493px;

    border-radius: 0px;

}

.image-main-section {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.about-main-conetnetn-h2 {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    padding-bottom: 2rem;

    color: #4050AF;
}
.about-main-conetnetn-h2- {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    padding-bottom: 2rem;

    color: #4050AF;
}
.about-main-conetnetn-h2-- {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    padding-bottom: 2rem;

    color: #4050AF;
}
.about-main-content-parap {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* or 27px */



    /* Text color 2 */

    color: #686868;
}

/* Contact section  */
.contact-us {
    margin-top: 2%;

}



.main-serv-ces-h1-head {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #4050AF;
}
  

.main-services-para-main-card-top {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
padding-top:1rem ;    line-height: 37px;
    /* or 185% */

    letter-spacing: -0.01em;

    /* Text color 2 */

    color: #686868;
}

.card-top-main{
    font-family: 'Segoe UI';
font-style: normal;
font-weight: 600;
font-size: 12px;

color: #3D4DAE;
}
.main-top-bottom-content{
    font-family: 'Segoe UI';
font-style: normal;
font-weight: 350;
font-size: 12px;

/* or 138% */


color: #000000;

}

.arrivals-card-arrival{
    background: #07135F;
    height: 120vh;
    width: 100%;
}
.car-2-3-4{
    width: 270px;
    height: 280px;
    padding: 1.5rem;
    margin: 5px;
    background-color: #FEFEFE;
}
.arrivals-head-main-top-my-bottom{
    font-family: 'Segoe UI';
font-style: normal;
font-weight: 700;
font-size:3.5rem;
text-align: center;

color: #FFFFFF;
}

.arrival-main-bottom-top-head{
    font-family: 'Segoe UI';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 37px;
/* or 185% */

text-align: center;
letter-spacing: -0.01em;

color: #FFFFFF;
}
.arrival-main-top-boottom{
    font-family: 'Segoe UI';
font-style: normal;
font-weight: bolder;
font-size: 14px;
line-height: 32px;
/* identical to box height */

text-align: center;

color: #000000;
}
.arricval-card-content-main-last-top{
    font-family: 'Segoe UI';
font-style: normal;
font-weight: 400;
font-size: 14px;

text-align: center;

color: #000000;
}
.btn-car-arrivals-main-23{
    font-family: 'Segoe UI';
font-style: normal;
font-weight: 600;
font-size: 14px;
margin: auto;
width: 355px;
height: fit-content;
padding: 1rem;
margin-top: 2.5rem;

color: #140239;

}




/* margin top - */
.main-services-card-main-div{
    padding-top: 0!important;
    display: flex;
    margin: auto;
   
    
}

.main-roe{
    margin-top: -5rem !important;
}
.arrivals-card-arrival{
    margin-top: -4rem !important;
}
.newul {
    margin-bottom: 15%;
    list-style: circle;
}

.bolet-btn-main{
    padding: 11px 33px;
    
    width: 251px;
    height: 49px;
    letter-spacing: -0.01em;

    color: #FFFFFF;
    outline: none;
    border: none;
    
    /* PURPLE */
    
    background: #3D4DAE;
    
}
 .card-main-div-main-card {
        width: 21% !important;
        height: auto;
        margin: 10px auto !important;
        margin: 5PX;
        text-align: center;
        background: #FEFEFE;
        box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25); 
        padding: 1rem;
      }
@media only screen and (max-width: 880px) {
    .card-main-div-main-card {
        width: 45% !important;
        height: auto;
       margin: 10px auto !important;
      }
  
}
@media only screen and (max-width: 480px) {
   .Optimal{
    padding: 20px !important;
   }
    .arrivals-card-arrival
    {
        display: none;
    }
    .for-baground-images {
        background-image: url(../Assets/shopfygrade.png);
        background-size: cover;
        width: 100%;
        /* height: 100vh;
        margin-bottom: -90px; */
    }
    .for-baground-images1 {
        background-image: url(../Assets/shopfygrade.png);
        background-size: cover;
        width: 100%;
   
    
    }
    .for-baground-images2 {
        background-image: url(../Assets/shopfygrade.png);
        background-size: cover;
        width: 100%;
    
       
    }
    .main-serv-ces-h1-head {
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 64px;
        color: #4050AF;
    }
    .card-main-div-main-card{
        width: 100% !important;
    }
    .set{
        margin-left: 0px !important;
    }
    .set1{
        margin-left: 0px !important;
       
    }
    .set3{
        margin-left: 0px !important;
       
    }
    .newcardd{
        height: 300px;
        width: 260px;
        background-color: white;
        border-radius: 5px;
        margin-bottom: 20px!important;
        padding: 10px !important;
        
       
    }
    .whytop{
        margin-top: -110px;
    }
    .prob{
        margin-top: 10px;
    }
    .about-main-conetnetn-h2 {
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        padding-bottom: 2rem;
    
        color: #4050AF;
        margin-top: -14px;
    }
    .buto{
        width: 260px;
        height: 50px;
        background-color: white;
        border: none;
        border-radius: 5px;
        margin-top: 30px;
        margin-bottom: 30px;
        
        }
    .about-main-content-parap {
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        /* or 27px */
    
    
    
        /* Text color 2 */
    
        color: #686868;
        margin-bottom: -40px;
    }
    .arrivals-head-main-top-my-bottom{
        font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size:2rem;
    text-align: center;
    margin-bottom: 20px !important;
    color: #FFFFFF;
    }
    .main-hero-h1 {
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        color: #FFFFFF;

    }
    .main-hero-para-main-div {
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 350;
        font-size: 12px;
        color: #FFFFFF;
  
    }
    .about-main-conetnetn-h2- {
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        padding-bottom: 2rem;
    
        color: #4050AF;
    }
    .about-main-conetnetn-h2-- {
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        padding-bottom: 2rem;
    margin-top: 40px !important;
        color: #4050AF;
    }
    .main-services-card-main-div{
        padding-top: 0!important;
        display: flex;

   
        
    }
    .image-main-section img{
        height: 100%;
    }
  }
