/* App.css */

/* App.css */

.App2 {
    background-color: white;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .tab-container {
    max-width: 1150px;
    margin: 0 auto;
    padding: 10px;
  }
  
  .tab-scrollable {
    overflow-x: auto;
  }
  
  .tab {
    display: flex;
    /* Add the following style to limit button width */
    max-width: 100%;
  }
  
  .tab-btn {
    padding: 10px 10px;
    border: none;
    border-bottom: 1px solid lightgray;
    background-color: white;
    cursor: pointer;
    font-size: 16px;
    /* Add the following style to prevent text wrapping */
    white-space: nowrap;
    /* Add the following style to prevent button from growing too wide */
    flex: 1;
  }
  .tabcol{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
  }
  .techhead{
    font-size: 36px;
    font-weight: 700;
    color: #3D4DAE;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 63px;
  }
  .tab-btn.active {
    background-color: white;
    border-bottom: 2px solid #3D4DAE;
    color: #3D4DAE;
  }
  .phead{
    font-size: 16px;
    font-weight: 400;
    color: #52525B;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 34px;
  }
  .tab-content .tab-pane {
    display: none;
  }
  
  .tab-content .tab-pane.active {
    display: block;
  }
  
  /* Hide the vertical scrollbar */
  .tab-scrollable::-webkit-scrollbar {
    width: 0.5em;
  }
  
  .tab-scrollable::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  
  .tab-scrollable::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  /* Horizontal scrollbar */
  .tab-scrollable {
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox fallback */
  }
  
  .row5{
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
  
@media only screen and (max-width: 500px) {

  row5{
    margin: auto;
    width: 100%;
    height: 400px;
  }
}