.Hero-ection-banner-bg{
    width: 100%;
    height: 50%;
    background: url(../Assets/HeroBannerDemo.png);
    position: absolute;
}
.main-contenr{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6rem;
    
}

.sublogic{
    border: none;
    background-color: #0659A4 !important;
    color: white;
    width: 80px;
    padding: 5px;
    border-radius: 5px;
    margin-left: 20px;
}
.logicinp{
    width: 300px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    margin-bottom: 20px;
    margin-top: 20px;
  
}
.logicinp:focus{
    width: 300px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    margin-bottom: 20px;
    margin-top: 20px;
    outline: none;
}
.main-head-hero-section{
    color: #FFF;

font-family: 'Segoe UI';
font-size: 96px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.new-para-main-hero-section{
    color: #FFF;

font-family: 'Segoe UI';
font-size: 18px;
font-style: normal;

line-height: normal;
}
.form-new-01{
    position: relative;
  top: 20rem;
    width: 70%;
    height: 100%;
   
    border-radius: 18px;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05), 0px 13px 30px 0px rgba(0, 0, 0, 0.05), 0px 54px 54px 0px rgba(0, 0, 0, 0.04), 0px 121px 73px 0px rgba(0, 0, 0, 0.03), 0px 215px 86px 0px rgba(0, 0, 0, 0.01), 0px 337px 94px 0px rgba(0, 0, 0, 0.00);
    padding: 2rem;
}
.from-head{
    color: #00396D;
text-align: center;
padding: 2rem;
font-family: 'Segoe UI';
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.submit-btn button{
    display: flex;
width: 185px;
height: 44px;
padding: 2px 16px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 5px;
background: var(--dark, #0659A4);
color: #FFF;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Segoe UI';
font-size: 14px;
outline: none;
border: none;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */
}
.labelInout{
    color: var(--base-02, #090914);


font-family: 'Segoe UI';
font-size: 16px;
font-style: normal;

line-height: 24px; /* 150% */
}
.labelInout span{
    color: red;
}
.check{
    display: flex;
}
.Enter-Name{
    padding: 1rem;
    color: red;
}
.new-apra-manin{
    color: #00396D;
text-align: center;

font-family: 'Segoe UI';
font-size: 28px;

font-style: normal;
font-weight: 400;
line-height: normal;
}
.new-great{
    color: #00396D;
text-align: center;

font-family: 'Segoe UI';
font-size: 28px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.new-main-90{
    color: #00396D;
text-align: center;

font-family: 'Segoe UI';
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

@media only screen and (max-width: 620px) {
   .main-head-hero-section{
    font-size: 40px;
    line-height: 50px;
    text-align: center;
   }
   .new-para-main-hero-section{
    text-align: center;
    font-size: 18px;
    line-height: 30px;
   }
   .form-new-01{
    position: relative;
  top: 16rem;
    width: 94%;
    height: 100%;
   
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05), 0px 13px 30px 0px rgba(0, 0, 0, 0.05), 0px 54px 54px 0px rgba(0, 0, 0, 0.04), 0px 121px 73px 0px rgba(0, 0, 0, 0.03), 0px 215px 86px 0px rgba(0, 0, 0, 0.01), 0px 337px 94px 0px rgba(0, 0, 0, 0.00);
    padding: 1rem;
}
.from-head{
    color: #00396D;
text-align: center;
padding: 2rem;
font-family: 'Segoe UI';
font-size: 38px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.main-contenr{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5rem;
    
}
.Hero-ection-banner-bg{
    width: 100%;
    height: 45%;
    background: url(../Assets/HeroBannerDemo.png);
    position: absolute;
}
.check{
    display: block;
}
  }

  .calender-section-09{
    width: 100%;
    height: 100%;
    
    display: flex;
    gap: 1rem;
    flex-direction: row;
    border-radius: 15px;
border: 0.5px solid #BFBFBF;
background: #FFF;
box-shadow: 0px 39px 39px 0px rgba(0, 0, 0, 0.04), 0px 87px 52px 0px rgba(0, 0, 0, 0.03);
padding-bottom: 1rem;

}
.calender-90{
    width: 50%;
    height: 100%;
 
    display: flex;
    justify-content: center;
    align-items: center;
}
.calender-content{
    width: 50%;
    height: 100%;
  
 
   
   
}
.calnder-head{
    color: #5F5F5F;
text-align: center;

font-family: 'Segoe UI';
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.time-secdule-89 h3{
    color: #00396D;
text-align: center;

font-family: 'Segoe UI';
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.time-to{
    color: #000;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Segoe UI';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
width: 148px;
padding: 12px 49px 12px 50px;
justify-content: center;
align-items: center;
border-radius: 8px 0px 0px 8px;
border: 0.3px solid #000;
background: #F1F1F1;
}
.time-then{
    color: #000;
    text-align: center;
   
    font-family:'Segoe UI';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
width: 148px;
padding: 12px 49px 12px 50px;
justify-content: center;
align-items: center;
border-radius: 0px 8px 8px 0px;
border-top: 0.3px solid #000;
border-right: 0.3px solid #000;
border-bottom: 0.3px solid #000;

}
.work-best0time{
    color: #00396D;
text-align: center;

font-family: 'Segoe UI';
font-size: 24px;


font-weight: 400;

}
.UTC-time{
    color: #00396D;
text-align: center;

font-family: 'Segoe UI';
font-size: 16px;

font-weight: 400;

}
.time-manage-btn{
    display: flex;
    flex-direction: column;
}
.time-secletin-01{
    display: flex;
width: 353px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
border: 1px solid #00396D;
color: #00396D;
text-align: center;
background: transparent;
font-family: 'Segoe UI';
font-size: 16px;
font-style: normal;
margin: 5px;
font-weight: 400;
line-height: normal;
}
.calender-time{
    color: #00396D;


font-family: 'Segoe UI';
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
}


.time-secdules-btns .min-15{
    
    width: 148px;
    padding: 12px 49px 12px 50px;
    justify-content: center;
    align-items: center;
    border-radius: 8px 0px 0px 8px;
border: 0.3px solid #000;

color: #000;
border-radius: 8px 0px 0px 8px;
border: 0.3px solid #000;
background: transparent;
outline: none;
font-family: 'Segoe UI';
text-align: center;

}
.time-secdules-btns .min-30{
    width: 148px;
    padding: 12px 49px 12px 50px;
    justify-content: center;
    align-items: center;
    border-radius: 8px 0px 0px 8px;
    border-radius: 0px 8px 8px 0px;
    border-top: 0.3px solid #000;
    border-right: 0.3px solid #000;
    border-bottom: 0.3px solid #000;
    background: transparent;

outline: none;
font-family: 'Segoe UI';
text-align: center;
} 
.work-best-time{
    color: #00396D;

font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Segoe UI';
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.utc-time{
    color: #00396D;


font-family: 'Segoe UI';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
@media only screen and (max-width: 600px) {
    .calender-section-09{
        width: 100%;
        height: 100%;
       text-align: center;
        display: flex;
        gap: 1rem;
        flex-direction:  column;
        border-radius: 15px;
    border: 0.5px solid #BFBFBF;
    background: #FFF;
    box-shadow: 0px 39px 39px 0px rgba(0, 0, 0, 0.04), 0px 87px 52px 0px rgba(0, 0, 0, 0.03);
    padding-bottom: 1rem;
    
    }
    .react-calendar{
    width: 300px !important;
    }
    .time-secletin-01{
        display: flex;
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #00396D;
    color: #00396D;
    text-align: center;
    background: transparent;
    font-family: 'Segoe UI';
    font-size: 16px;
    font-style: normal;
    margin: 5px;
    font-weight: 400;
    line-height: normal;
    }
    .calender-content{
        width: 98%;
        height: 100%;
      
     
       
       
    }
    .calender-90{
        width: 100%;
        height: 100%;
     
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .time-secletin-01{
        display: flex;
    width: 321px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #00396D;
    color: #00396D;
    text-align: center;
    background: transparent;
    font-family: 'Segoe UI';
    font-size: 16px;
    font-style: normal;
    margin: 5px;
    font-weight: 400;
    line-height: normal;
    }
    .new-main-90 {
        color: #00396D;
        text-align: center;
        font-family: 'Segoe UI';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
  }