.hero {
  background-image: url("./Assets/newhero5.png");
  background-color: rgba(0, 9, 24, 1);
  background-repeat: no-repeat;
  height: fit-content;
  height: 700px;
  margin-top: -30px;

}
.uperdiv{
  height: 120px;
  }
    

a {
  text-decoration: none;
}

.conti {
  font-size: 48px;
}

.our_services {
  background: #F6F6F6;
}

.text-askari-primary {
  margin-top: 50px;
  color: #0659A4 !important;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.row2 {
  margin:  auto;
  display: flex;
  width: 100%;
  gap: 5px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.row10{
  display: flex;
  flex-direction: column;
}


.card2{
  width: 20%;
  margin: 20px;
  display: grid;
}
.our_development_process_section .card {
  background: #FBFBFB;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.requirment_analysis_card {
  border-radius: 25px !important;
  border-left: 10px solid #EC9B00 !important;
  border-right: 10px solid #EC9B00 !important;
  border-bottom: 10px solid #EC9B00 !important;
}

.building_the_right_team_card {
  border-radius: 25px !important;
  border-left: 10px solid #6471D7 !important;
  border-right: 10px solid #6471D7 !important;
  border-top: 10px solid #6471D7 !important;
}

.planning_and_execution_card {
  border-radius: 25px !important;
  border-left: 10px solid #20AF92 !important;
  border-right: 10px solid #20AF92 !important;
  border-bottom: 10px solid #20AF92 !important;
}

.review_and_delivery_card {
  border-radius: 25px !important;
  border-left: 10px solid #F1323C !important;
  border-right: 10px solid #F1323C !important;
  border-top: 10px solid #F1323C !important;
}

.project_delivered {
  background: #00386B;
}

.project_delivered_details_card {
  background: transparent !important;
  border: none !important;
  color: white !important;
  padding: 10px;
  width: 100% !important;
  height: 347px !important;
  transition: opacity 0.6s ease !important;

}


@media (max-width: 767px) {

  .project_delivered_details_card {
    width: 60% !important;
    height: 399px !important;

  }

}

.services img {
  margin-right: 40px;
}

.services img::after {
  content: '';
  height: 58px;
  width: 10px;
  border: 5px solid red;
}


.web_dev_services_details {
  background: linear-gradient(90deg, rgba(236, 155, 0, 0.19) 1.24%, rgba(236, 155, 0, 0) 97.9%);
  padding: 30px;
  border-radius: 2px;
}

.app_dev_services_details {
  border-radius: 2px;
  padding: 30px;
  background: linear-gradient(90deg, rgba(108, 115, 203, 0.19) 1.24%, rgba(107, 115, 203, 0) 97.9%);
}

.web_dev_service_box_btn {
  margin: 50 50px;
}

.web_dev_service_btn {
  background: #FBE7C2 !important;
  border: 1px solid #EC9B00 !important;
  margin-bottom: 10px !important;
  border-radius: 5px;
}

.app_dev_service_btn {
  border: 1px solid #6471D7 !important;
  background: #DCDDEE !important;
  margin-bottom: 10px !important;
  border-radius: 5px;
}

.enterprise_service_btn {
  border-radius: 5px;
  margin-bottom: 10px !important;
  border: 1px solid #BF9752 !important;
  background: #ECE5D9 !important;
}

.webDevVector {
  position: relative;
  top: -170px !important;
  right: 120px;
  margin-bottom: -160px;
}

.AppDevVector {
  position: relative;
  top: -150px !important;
  right: -100px !important;
  margin-bottom: -160px;
}

.EnterpriseDevVector {
  position: relative;
  top: -150px !important;
  right: -100px !important;
  margin-bottom: -160px !important;
}

.our_services {
  margin-bottom: 150px !important;
}


@media (max-width: 767px) {

  .webDevVector {
    position: static;
    margin-top: -10px;
    margin-bottom: -30px;
  }

  .AppDevVector {
    position: static;
    margin-top: -10px;
    margin-bottom: -30px;
  }

  .project_delivered_details_card {
    width: 100% !important;
    background-color: red;
  }
  .cardp {
    color: white !important;
    font-size: 10px  !important ;
  }

}

.app_dev_btn {
  background: #6471D7 !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: white !important;
}

.enterprise_dev_services_details {
  background: linear-gradient(90deg, rgba(191, 151, 82, 0.19) 1.24%, rgba(191, 151, 82, 0) 97.9%);
  padding: 30px;
  border-radius: 5px;
}

.enterprise_dev_btn {
  background: #BF9752;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.software_service_btn {
  background: #DCDDEE !important;
  border: 1px solid #6471D7 !important;
  border-radius: 5px;
  margin-bottom: 10px !important;
}

.software_dev_btn {
  /* software devlop color */

  background: #6471D7;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: white !important;
}

.software_service_details {
  padding: 30px;
  background: linear-gradient(90deg, rgba(100, 113, 215, 0.19) 1.24%, rgba(100, 113, 215, 0) 97.9%);
  border-radius: 5px;
}

.content_service_details {
  padding: 30px;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(32, 175, 146, 0.19) 1.24%, rgba(32, 175, 146, 0) 97.9%);
}

.content_service_btn {
  background: #CEE8E3 !important;
  border: 1px solid #20AF92 !important;
  border-radius: 5px;
  margin-bottom: 10px !important;
}

.creative_service_btn {
  border: 1px solid #91B3FA !important;
  border-radius: 5px;
  margin-bottom: 10px !important;
  background: #D6DFF2 !important;
}

.sm_service_btn {
  border: 1px solid #C86485 !important;
  border-radius: 5px;
  margin-bottom: 10px !important;
  background: #EEDBE1 !important;
}

.automatin_service_btn {
  border: 1px solid #FE776B !important;
  border-radius: 5px;
  margin-bottom: 10px !important;
  background: #EEDBE1 !important;
}

.ml_service_btn {
  border: 1px solid #58B5D9 !important;
  border-radius: 5px;
  margin-bottom: 10px !important;
}

.content_service_dev_btn {
  /* Content Management */

  background: #20AF92;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: white !important;
}

.creative_service_details {
  padding: 30px;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(76, 124, 224, 0.19) 1.24%, rgba(76, 124, 224, 0) 97.9%);
}

.ml_service_details {
  padding: 30px;
  border-radius: 5px;
  background: linear-gradient(to right, #84C8E2, #FFFFFF) !important;
}

.creative_service_dev_btn {
  /* creative */

  background: #4C7CE0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: white !important;
}

.sm_service_details {
  padding: 30px;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(200, 100, 133, 0.19) 1.24%, rgba(200, 100, 133, 0) 97.9%);
}

.sm_service_dev_btn {
  /* social media */

  background: #C86485;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: white !important;
}

.automation_service_details {
  padding: 30px;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(200, 100, 133, 0.19) 1.24%, rgba(200, 100, 133, 0) 97.9%);
}

.automation_service_dev_btn {
  /* automation s */

  background: #FE776B;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: white !important;
}

.project_delivered {
  background: #00386B;
  position: relative;
  z-index: 1;
}

.project_delivered_details_card {
  background: #FBF0DD;
  padding: 15px;
}

.app_dev_service_btn_accordion {
  background: linear-gradient(90deg, rgba(108, 115, 203, 0.19) 1.24%, rgba(107, 115, 203, 0) 97.9%);
  border: 1px solid #6471D7;
  border-radius: 5px !important;
  margin-bottom: 10px !important;
}

.enterprise_dev_service_btn_accordion {
  background: linear-gradient(90deg, rgba(191, 151, 82, 0.19) 1.24%, rgba(191, 151, 82, 0) 97.9%);
  border-radius: 5px !important;
  margin-bottom: 10px !important;
  border: 1px solid #BF9752;

}

.software_dev_service_btn_accordion {
  background: linear-gradient(90deg, rgba(100, 113, 215, 0.19) 1.24%, rgba(100, 113, 215, 0) 97.9%);
  border: 1px solid #6471D7;
  border-radius: 5px !important;
  margin-bottom: 10px !important;
}

.content_dev_service_btn_accordion {
  border-radius: 5px;
  margin-bottom: 10px !important;
  background: linear-gradient(90deg, rgba(32, 175, 146, 0.19) 1.24%, rgba(32, 175, 146, 0) 97.9%);
  border: 1px solid #20AF92;
}

.social_dev_service_btn_accordion {
  background: linear-gradient(90deg, rgba(200, 100, 133, 0.19) 1.24%, rgba(200, 100, 133, 0) 97.9%);
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #C86485;
}

.automation_service_btn_accordion {
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #FE776B;
  background: linear-gradient(90deg, rgba(200, 100, 133, 0.19) 1.24%, rgba(200, 100, 133, 0) 97.9%);
}

.requirement_analysis_card {
  background: #FBFBFB;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  height: 275px;
  border-right: 10px solid orange;
  border-bottom: 10px solid orange;
  border-left: 10px solid orange;
}

.btrt_process_card {
  background: #FBFBFB;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  border-right: 10px solid #6471D7;
  border-top: 10px solid #6471D7;
  border-left: 10px solid #6471D7;
}

.pae_process_card {
  background: #FBFBFB;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  border-right: 10px solid #20AF92;
  border-bottom: 10px solid #20AF92;
  border-left: 10px solid #20AF92;
}

.pae_process_card_header {
  padding: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: -2rem 1rem 1rem;
  border-radius: 50% !important;
  border: 10px solid #20AF92;
  width: 96.24px !important;
  height: 96.28px !important;
  align-self: center !important;
}

.rad_process_card {
  background: #FBFBFB;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  border-right: 10px solid #F1323C;
  border-top: 10px solid #F1323C;
  border-left: 10px solid #F1323C;
}

.rad_process_card_header {
  padding: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: -2rem !important;
  border-radius: 50% !important;
  border: 10px solid #F1323C;
  width: 96.24px !important;
  height: 96.28px !important;
  align-self: center !important;
}

.btrt_process_card_footer {
  align-self: center;
  margin-bottom: -2rem !important;

  border-radius: 50% !important;
  border: 10px solid #6471D7;
  width: 96.24px !important;
  height: 96.28px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  background: white;
}

.requirement_analysis_card_header {
  padding: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: -2rem 1rem 1rem;
  border-radius: 50% !important;
  border: 10px solid #EC9B00;
  ;
  width: 96.24px !important;
  height: 96.28px !important;
  align-self: center !important;
}

.requirement_analysis_card_bulb {
  margin-top: 5px;
}

.contact_us_section {
  /* background-image: url("/map_bg.png"); */
  background-repeat: no-repeat;
  background-position: 0%;
}

.contact_us_card_sect_main {
  background: #000000 !important;
  border: 10px solid #FFFFFF;
  border-radius: 44px;
  margin: -3rem 1rem 1rem;
}

.btn-warning {
  background: #EC9B00 !important;
  color: black;
}

.slick-current .card {
  background: #FBF0DD !important;
  color: black !important;
  border-radius: 4px;
  height: 360px !important;
  transition: opacity 0.4s ease !important;
}

.cardp {
  color: white !important;
}

.slick-current .cardp {
  color: black !important;
}

.card {
  position: relative;

}

.slick-current .card-heading {
  position: absolute;
  top: 10%;
  left: 20%;
  transform: translate(-50%, -50%);
  font-size: 128px;
  opacity: 0.1;
  z-index: 1;
  display: block;
  animation: fade 3.5s linear infinite alternate;

}

@keyframes fade {
  0% {
    color: #ffffff;
    /* Light color */
  }

  100% {
    color: #000000;
    /* Dark color */
  }
}

.card-heading {
  position: absolute;
  top: 10%;
  left: 20%;
  transform: translate(-50%, -50%);
  font-size: 128px;
  opacity: 0.1;
  z-index: 1;
  display: none;

}

.card-text {
  position: relative;
  z-index: 2;
}

.btn-askari-primary {
  background: #0659A4 !important;
}


.custom_drop_down_menu {
  padding: 25px;
  width: 800px !important;
}

.development_lg_4 {
  background: #eee;
}
.row3{
  display: flex;

}

@media only screen and (max-width: 800px) {

  .card2{
    width: 35%;
    margin: 40px;
  }
}
@media only screen and (max-width: 500px) {
  .hidetab {
    display: none;
  }
  .row3{
    flex-direction: column;
  }

  .carcir1 {
    margin-top: 60px;

  }
  
  .carcir2 {
    margin-top: 50px;
  }
  .card2{
    width: 100%;
    margin: 10px;
    margin-top: 20px;
    margin-bottom: 50px;
    
  }

  .pad5 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .conti {
    font-size: 20px;
  }

  .hero {
    background-image: url("./Assets/hro.png");
    background-repeat: no-repeat;
    height: fit-content;
    height: 650px;
    margin-top: 1px;

  }
.uperdiv{
height: 50px;
}
 .talk{
  display: flex;
  margin: auto 45px ;
  align-items: center;
  width: 70%;
 } 
 .div3{
  display: flex;
  flex-direction: column;
  margin-left: 40px;
 }
 
}