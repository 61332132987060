@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,1000;1,200;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,600&family=Poppins:wght@500&family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400&family=Sora&family=Titillium+Web:ital,wght@0,200;0,300;1,300&family=Work+Sans:wght@500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mulish";

}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.conhide {
  display: none !important;
}

img {
  max-width: 100%;
}
.Stepmob{

  display: none;
}
.ui-card {
  width: 180px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-out;
  background: radial-gradient(#111 50%, #000 100%);
}

.ui-card img {
  transform: scale(1.3);
  transition: all 0.3s ease-out;
}

.ui-card:hover img {
  transform: scale(1.1) translateY(-20px);
  opacity: 0.3
}

.ui-card .description {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
}

.ui-card .description h3 {
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 20px;
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.3s ease-out;

}

.ui-card .description p {
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.3s ease-out 0.2s;
  font-size: 11px;
}

.ui-card .description a {
  color: #fff;
  background: #34aff3;
  display: inline-block;
  padding: 0px 25px;
  border-radius: 5px;
  text-decoration: none;
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.3s ease-out 0.4s;
}

.ui-card .description a:hover {
  background: #0a83c5;
}

.ui-card:hover .description h3 {
  opacity: 1;
  transform: translateY(0px);
}

.ui-card:hover .description p {
  opacity: 1;
  transform: translateY(0px);
}

.ui-card:hover .description a {
  opacity: 1;
  transform: translateY(0px);
}

.cardPadinng {
  margin-top: 100px;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CardHeading {
  color: #3d4dae;
  font-size: 24px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.bannner-image-qua {
  width: 100%;
  max-width: 100%;
  height: 120%;
  display: flex;
  color: white;
  padding-left: 15%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../Assets/banner2.png");
  padding-top: 5%;
  padding-bottom: 5%;
}

.bannner-image-ai {
  width: 100%;
  max-width: 100%;
  height: 110%;
  display: flex;
  color: white;
  padding-left: 15%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../Assets/ai_banner.png");
  padding-top: 3%;
  padding-bottom: 3%;
}

.bannner-image-devo {
  width: 100%;
  max-width: 100%;
  height: 110%;
  display: flex;
  color: white;
  padding-left: 15%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../Assets/devop-banner.png");
  padding-top: 5%;
  padding-bottom: 5%;
}

.bannner-image-clo {
  width: 100%;
  max-width: 100%;
  height: 110%;
  display: flex;
  color: white;
  padding-left: 15%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../Assets/cloudsolhead.png");
  padding-top: 5%;
  padding-bottom: 5%;
}

.MainHeading {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 64px;
  font-weight: 500;
  line-height: 94px;
}

.Quality {
  text-align: left;
  margin-bottom: 50px;
}

.headPara {
  margin-top: 40px;
  margin-bottom: 50px;
}

.bannner-image p {
  position: relative;
  top: 10%;
  font-size: 1.3rem;
}

.text-content {
  margin-top: 12%;
  padding-top: 4%;
  margin-left: 10%;
  line-height: 2;
}






.mainsection {
  background: #f6f6f6;
  width: 100vw;
  display: flex;
}

.servicespara h3 {
  color: #3d4dae;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;


}



.erpsoftware {
  margin-top: 5%;
}

.erpsoftware h3 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;

  line-height: 64px;
  color: #4050af;
}

.erpsoftware p {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  line-height: 37px;
  margin-top: 2%;
  text-align: left;
  letter-spacing: -0.01em;
  text-align: center;
  color: #686868;
}

.image-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.align_product {

  display: flex;
  align-items: flex-start;

}

/* .image-item {
  width: 700px;
  height: 400px;
  margin: 10px;
  
} */

.image-item img {
  width: 100%;
  height: 100%;

}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  text-align: left;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  line-height: 25px;
  color: #ffffff;
}

.caption p {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 350;
  font-size: 14px;

  line-height: 19px;
}

.caption h4 {
  margin-bottom: 2%;
}

@media screen and (max-width: 767px) {
  .text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30%;
    line-height: 2;
  }

  .bannner-image-qua {
    width: 100%;
    max-width: 100%;
    height: 45vh;
    display: flex;
    color: white;
    padding-left: 15%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../Assets/banner2.png");
    padding-top: 5%;
  }

  .text-content button {
    width: fit-content;
    margin-top: 2rem;
  }
}

.main-section-1 {
  height: 100%;
  width: 100vw;
  background: linear-gradient(90deg, #09197a 34.42%, #3d4dae 100%);
  /* width: 100vw; */
}

.Main-ul {
  list-style: none;
}

.Main-ul a {
  list-style: none;
  text-decoration: none;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
}

.Brand-Name {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 2.5rem;
  color: #ffffff;
}

.Brand-slogen {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 350;
  font-size: 16px;

  color: #ffffff;
  padding-top: 1rem;
}

.Hire-btn {
  background: #ffffff;
  border-radius: 3px;

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;

  line-height: 27px;
}

/* Section 2 Css */

.Main-About-img-2 {
  width: 99%;
  height: 100%;
}

.about-head {
  color: rgba(64, 80, 175, 1);
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
}

.Brand-slogen {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  line-height: 133.01%;
}

.main-2 {
  background: #f8f9fc;
}

/* Contact section  */
.contact-us {
  margin-top: 4%;
}

.image-section {
  position: relative;
}

.image-section img {
  width: 100%;
  height: 100vh;
  display: block;
 
}

.skilled h3 {
  font-family: "Segoe UI";
  font-style: normal;

  line-height: 69px;
  text-align: left;

  /* PURPLE */

  color: #3d4dae;
}

.skilled {
  margin-left: 4%;
  margin-top: 5%;

  width: 50%;
}

.skilled44 h1 {

  color: #3d4dae;
  font-weight: bolder;

  margin-top: 5%;

}

.skilled p {
  font-family: "Segoe UI";
  font-style: normal;

  font-size: 16px;

  color: #686868;
  /* or 185% */

  letter-spacing: -0.01em;

  /* Text color 2 */
  text-align: left;
  color: #686868;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .image-section img {
    display: none;
    height: 250px;
    width: 100%;
    margin-top: 5px;
  }
}



.section {
  text-align: center;
  margin-left: 2rem;
}

.section h2 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 64px;
  /* identical to box height, or 176% */

  /* PURPLE */

  color: #3d4dae;
}

.card-row {
  display: flex;
  justify-content: center;

}


.askaritechnology {
  display: flex;

}

.cards_qa {

  height: 40%;
  width: 100%;
  padding-top: 20px;


}

.content_qa_card {
  height: 60%;
  width: 100%;
  text-align: center;
  color: #686868;
  font-size: 14px;
  padding: 5px;

}

.content_qa_card h5 {


  color: #3d4dae;
}

.inner_card_qa {
  height: 50%;
  width: 22%;
  background-color: #f4f3f3;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.inner_card_dev {
  height: 100%;
  width: 22%;

}

.main_card_section_qa {
  height: 60%;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30%;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-evenly;
}

.heading_cards_qa {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: bolder;
  font-size: 40px;
  margin-bottom: 50px;
  color: #3d4dae;
}

.main {
  width: 100%;
  margin-top: 10vh;
}

.left-side {
  flex: 1;
  padding: 20px;
}

.left-side img {
  width: 100%;
  height: auto;
}

.right-side {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.right-side h3 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: bolder;
  font-size: 40px;
  margin-bottom: 50px;

  /* identical to box height */

  /* PURPLE */

  color: #3d4dae;
}

.right-side p {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  /* Text color 2 */

  color: #686868;
}

.right-side button {
  background: #3d4dae;
  border-radius: 4px;
  width: 251px;
  height: 49px;
  margin-top: 4%;
  color: white;
}

.icon {
  padding: 15px 15px 15px 15px;
}

.card img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.card h3,
.card p {
  color: #000;
  background-color: white;
  padding: 10px;
  margin: 0;
}

.flags {
  margin-top: 70px;
}

.flagT {
  color: #0659a4;
  font-size: 24px;
  margin-top: 35px;
  font-weight: 600;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.coll {
  text-align: center;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .icon-row {
    flex-direction: column;
  }

  .icon {
    margin: 10px 0;
  }
}

@media screen and (max-width: 600px) {
  .card-row {
    flex-wrap: wrap;
  }
}

.conatact-tittle {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;

  line-height: 96px;
  color: #3d4dae;
}

.conact-c2 {
  background: #f8f9fc;
}

.btn-started {
  background: #3d4dae;
  border-radius: 9px;
  padding: 16px;
  width: 188px;
  height: 55px;
  color: #ffffff;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  line-height: 25px;
  align-items: center;
  text-align: center;
  outline: none;
  border: none;
}

.guaranteed {
  color: #3d4dae;
}

.services {
  background: #f8f9fc;
}

.services-head {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;

  text-align: center;
  line-height: 64px;
  color: #4050af;
}

.services-para {
  text-align: start;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #686868;
  line-height: 37px;
}

.services-card {
  /* max-height: fit-content !important; */
  padding: 1rem;
  background: #fefefe;
  margin: 0.5rem;
  box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);
}

.car-heading {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;

  line-height: 26px;

  letter-spacing: -0.01em;

  color: #3d4dae;
}

.card-para {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 350;
  font-size: 13px;

  line-height: 18px;

  color: #000000;
}

.services-row {
  align-items: center;
  justify-content: center;
  display: flex;
}

.arrival-card {
  background: #fefefe;
  text-align: center;
  max-width: fit-content;
  box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);
}

.Arrival-head {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;

  line-height: 32px;

  text-align: center;

  color: #3d4dae;
}

.icons-arrival {
  font-size: 3rem;

  color: rgba(61, 77, 174, 1);
}

.arrival-head {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  line-height: 26px;
  /* or 142% */

  text-align: center;
  letter-spacing: -0.01em;
}

.arrival-head {
  font-size: 16px;
}

.hireDeveloper {
  background: #fefefe;
}

.heading {
  color: #3d4dae;
  font-size: 48px;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}

.subHead {
  color: #52525b;
  font-size: 18px;
  margin-top: 25px;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: center;
}

.column {
  padding: 50px;
  text-align: center;
  /* Align content in the center */
}

.name {
  font-weight: bold;
  line-height: 28px;
  font-size: 21px;
  margin-top: 30px;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.enterprise {
  margin-top: 5%;
}

.enterprise h3 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 64px;
  color: #3d4dae;
}

.enterprise .para1 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 37px;
  margin-top: 4%;
  /* or 185% */

  text-align: center;
  letter-spacing: -0.01em;

  /* Text color 2 */

  color: #686868;
}

.steps p {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  text-align: center;

  /* Text color 2 */

  color: #686868;
}

.responsive-section {
  position: relative;
  width: 100vw;
  /* Use viewport width */
  height: 600px;

  margin-top: 2%;
}

.background-image {
  width: 100vw;
  /* Use viewport width */
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
}

.content {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: #ffffff;
  z-index: 1;
  width: 100%;
  max-width: 600px;
  /* Adjust the width as per your needs */
  padding-left: 20px;
  /* Adjust the left margin as per your needs */
  box-sizing: border-box;
}

.heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.paragraph {
  font-size: 18px;
  margin-bottom: 0;
}

.codheading {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 46px;
}

.content .paragraph {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 8%;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.check-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.feature-text {
  margin: 0;
}

.checkicons {
  margin-top: 5%;
}

.section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5%;
}

.left {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left img {
  max-width: 100%;
  max-height: 100%;
}

.right {
  flex-basis: 50%;
  padding: 0 20px;
}

.right h2 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 64px;
  color: #3d4dae;
}

.right p {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 37px;
  /* or 185% */

  display: flex;
  align-items: flex-end;
  letter-spacing: -0.01em;

  /* Text color 2 */

  color: #686868;
}

.right li {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 37px;
  /* or 185% */

  letter-spacing: -0.01em;

  /* Text color 2 */

  color: #686868;
}

.container-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f4f3f3;
  padding: 60px 0px 60px 0px;
}

.container-icon h2 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 64px;

  /* PURPLE */

  color: #3d4dae;
}

.container-icon p {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* gray/600 */

  color: #52525b;
}

/* CSS for icons */
.icon-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.icon {
  margin: 10px;
}


.custom-hero-content {
  max-width: 800px;
  padding: 20px;
}

.custom-hero-content h3 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 62px;

  text-align: center;

  color: #ffffff;
}

.custom-hero-content p {
  margin-bottom: 20px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #cbd5e1;
  text-align: center;
  margin-top: 4%;
}

.HowWeGo {
  text-align: left;
  margin-top: 40px;
  margin-bottom: -40px;
  font-size: 36px;
  color: #3d4dae;
  font-weight: bolder;
  padding: 10px 30px 10px 30px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.custom-hero-content .custom-button {
  padding: 10px 20px;

  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-top: 4%;
  color: #040108;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

@media (max-width: 768px) {
  .custom-hero-content {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .section {
    flex-direction: column;
  }

  .left,
  .right {
    flex-basis: 100%;
  }
}

@media (max-width: 991.98px) {
  .feature {
    flex-direction: column;
  }

  .check-icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .processSteps {
    flex-direction: column;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 767px) {
  .container {
    padding: 0;
    /* Remove container padding to align columns in a straight line */
  }

  .column {
    padding: 10px;
    /* Adjust the padding for mobile devices */
  }
}

.leftImage {
  width: 529px;
  height: 536px;
}

@media screen and (max-width: 1200px) {
  .mainsection {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
  }





}

@media screen and (max-width: 900px) {
  .erpsoftware h3 {
    text-align: initial;
  }

  .erpsoftware p {
    text-align: left;
  }

  .leftImage {
    width: 229px;
    height: 236px;
  }
}

@media screen and (max-width: 420px) {
  .heading_cards_qa {
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: bolder;
    font-size: 35px;
    margin-bottom: 50px;
    color: #3d4dae;
  }

  .cards_qa {
    display: none;
    height: 110vh;
    width: 100%;
    padding-top: 20px;

  }

  .bannner-image-ai {
    width: 100%;
    max-width: 100%;
    height: 120%;
    display: flex;
    color: white;
    padding-left: 10%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: none;
    background-color: rgba(40, 3, 117, 0.73);
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .conhide {
    display: block !important;
  }

  .bannner-image-devo {
    width: 100%;
    max-width: 100%;
    height: 120%;
    display: flex;
    color: white;
    padding-left: 7%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: none;
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: rgba(40, 3, 117, 0.73);
  }

  .main_card_section_qa {

    width: 100%;
    margin-top: 20px;
    margin-bottom: 150vh;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;


  }
  .Stepmob{

    display: block;
  }
  .skilled p {
    font-family: "Segoe UI";
    font-style: normal;

    font-size: 16px;

    color: #686868;
    /* or 185% */

    letter-spacing: -0.01em;

    /* Text color 2 */
    text-align: center;
    color: #686868;
  }

  .bannner-image-clo {
    width: 100%;
    max-width: 100%;
    height: 56vh;
    display: flex;
    color: white;
    padding-left: 10%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../Assets/cloudsolhead.png");
    padding-top: 10%;
  }

  .skilled h3 {
    font-family: "Segoe UI";
    font-style: normal;

    line-height: 39px;
    text-align: center;

    /* PURPLE */

    color: #3d4dae;
  }

  .skilled {
    width: 90%;
    padding: 15px;
  }

  .inner_card_qa {
    height: 22%;
    width: 90%;
    background-color: #f4f3f3;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    margin-left: 20px;
  }

  .main {
    width: 100%;
    margin-top: 270vh;

    display: none;
    flex-direction: column;
  }

  .hidecards {
    display: none;
  }


}